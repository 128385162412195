import React from "react";
import { useRoutes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import RTL from "./layouts/full-layout/customizer/RTL";
import ThemeSettings from "./layouts/full-layout/customizer/ThemeSettings";
import Router from "./routes/Router";
import "react-perfect-scrollbar/dist/css/styles.css";
import { BookingProvider } from "./context/booking";

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);

  /* The `return` statement in the `App` component is returning a JSX (JavaScript XML) expression. */
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <BookingProvider>
          {routing}
        </BookingProvider>

      </RTL>
    </ThemeProvider>
  );
};

export default App;
