import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import { configureStore } from './redux/Store';
import './data';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Spinner from './views/spinner/Spinner';
import { apiGetSession } from './apis';

const initialization = async () => {
  try {
    let result = await apiGetSession();
    localStorage.setItem('session', result.init);

    const App = lazy(() => import('./App'));

    const rootElement = document.getElementById('root');
    const root = createRoot(rootElement); // crea la raíz

    root.render(
      <Provider store={configureStore()}>
        <Suspense fallback={<Spinner />}>
          <HashRouter>
            <App />
          </HashRouter>
        </Suspense>
      </Provider>
    );
  } catch (error) {
    //console.log("Initialization", error);
  }
};

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

initialization();
