import React, { useState, useEffect } from 'react';
import { apiGetAllVehicleUser, apiGetAllTypeFuel, apiGetAllRegion, apiGetAllComune } from '../apis';


const BookingContext = React.createContext();

export const BookingProvider = ({ children }) => {
  // Estados para almacenar los datos
  const [vehicle, setVehicle] = useState([]);
  const [regions, setRegions] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [fuel, setFuel] = useState(localStorage.getItem('fuel') || null);
  const [comune, setComune] = useState(localStorage.getItem('comune') || null);
  const [region, setRegion] = useState(localStorage.getItem('region') || null);
  const [category, setCategory] = useState(localStorage.getItem('category') || null);
  const [service, setService] = useState(localStorage.getItem('service') || null);
  const [dateBooking, setDateBooking] = useState(localStorage.getItem('dateBooking') || null);
  const [timeBooking, setTimeBooking] = useState(localStorage.getItem('timeBooking') || null);
  const [checkWhatsApp, setCheckWhatsApp] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const [commentClient, setCommentClient] = useState(localStorage.getItem('commentClient') || '');
  

  
  useEffect(() => {

    fetchVehicleData();
    fetchFuelData();
    fetchRegionData();

  }, []);

  const fetchVehicleData = async () => {
    try {
      const response = await apiGetAllVehicleUser();
      setVehicle(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };
 
  const fetchFuelData = async () => {
    try {
      const response = await apiGetAllTypeFuel();
      setFuel(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error("Error fetching fuel types:", error);
    }
  };
    const fetchRegionData = async () => {
      try {
        const response = await apiGetAllRegion();
        setRegions(response ? response : []);     
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    };

  const setRegionData = async (isoCode) => {
    setRegion(isoCode)
    try {
      const response = await apiGetAllComune(isoCode); 
      setCommunes(Array.isArray(response) ? response : []);  
    } catch (error) {
      console.error("Error fetching communes:", error);
      setCommunes([]);  
    }
  };


  useEffect(()=>{
    setRegionData(region)
  },[region])


  
  useEffect(() => {
 
    localStorage.setItem('fuel', fuel);
    localStorage.setItem('comune', comune);
    localStorage.setItem('region', region);
    localStorage.setItem('category', category);
    localStorage.setItem('service', service);
    localStorage.setItem('dateBooking', dateBooking);
    localStorage.setItem('timeBooking', timeBooking);
    localStorage.setItem('commentClient', commentClient);



  }, [fuel, comune, region, category, service, dateBooking, timeBooking, commentClient]);

  // Funciones para actualizar los estados
  const setFuelData = (data) => setFuel(data);
  const setCityData = (data) => setCity(data);
  const setComuneData = (data) => setComune(data);
  const setCategoryData = (data) => setCategory(data);
  const setServiceData = (data) => setService(data);
  const setDateBookingData = (data) => setDateBooking(data);
  const setTimeBookingData = (data) => setTimeBooking(data);
  const setCheckWhatsAppData = (data) => setCheckWhatsApp(data);
  const setCheckTermsData = (data) => setCheckTerms(data);
  const setCommentClientData = (data) => setCommentClient(data);

  return (
    <BookingContext.Provider
      value={{
        vehicle,
        fuelTypes,
        regions,
        communes,
        fuel,
        comune,
        region,
        category,
        service,
        dateBooking,
        timeBooking,
        checkWhatsApp,
        checkTerms,
        commentClient,
        fetchVehicleData,
        setRegionData,
        setFuelData,
        setCityData,
        setComuneData,
        setRegionData,
        setCategoryData,
        setServiceData,
        setDateBookingData,
        setTimeBookingData,
        setCheckWhatsAppData,
        setCheckTermsData,
        setCommentClientData,
        fetchRegionData,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export const useBooking = () => React.useContext(BookingContext);
